import(/* webpackMode: "eager" */ "/app/apps/ashtrom-base/theme/ThemeProviders.tsx");
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/@apollo+experimental-nextjs-app-support@0.6.0_@apollo+client@3.8.8_next@14.1.0_react@18.2.0/node_modules/@apollo/experimental-nextjs-app-support/dist/ssr/ApolloNextAppProvider.js");
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/@apollo+experimental-nextjs-app-support@0.6.0_@apollo+client@3.8.8_next@14.1.0_react@18.2.0/node_modules/@apollo/experimental-nextjs-app-support/dist/ssr/hooks.js");
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next-intl@3.5.3_next@14.1.0_react@18.2.0/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.1.0_@babel+core@7.23.7_react-dom@18.2.0_react@18.2.0/node_modules/next/font/local/target.css?{\"path\":\"app/[locale]/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"../../public/fonts/rag/RagSans-1.4-Regular.woff2\",\"weight\":\"400\"},{\"path\":\"../../public/fonts/rag/RagSans-1.4-Regular.woff\",\"weight\":\"400\"},{\"path\":\"../../public/fonts/rag/RagSans-1.4-Medium.woff2\",\"weight\":\"500\"},{\"path\":\"../../public/fonts/rag/RagSans-1.4-Medium.woff\",\"weight\":\"500\"},{\"path\":\"../../public/fonts/rag/RagSans-1.4-SemiBold.woff2\",\"weight\":\"600\"},{\"path\":\"../../public/fonts/rag/RagSans-1.4-SemiBold.woff\",\"weight\":\"600\"},{\"path\":\"../../public/fonts/rag/RagSans-1.4-Bold.woff2\",\"weight\":\"700\"},{\"path\":\"../../public/fonts/rag/RagSans-1.4-Bold.woff\",\"weight\":\"700\"},{\"path\":\"../../public/fonts/rag/RagSans-1.4-ExtraBold.woff2\",\"weight\":\"800\"},{\"path\":\"../../public/fonts/rag/RagSans-1.4-ExtraBold.woff\",\"weight\":\"800\"},{\"path\":\"../../public/fonts/rag/RagSans-1.4-Black.woff2\",\"weight\":\"900\"},{\"path\":\"../../public/fonts/rag/RagSans-1.4-Black.woff\",\"weight\":\"900\"},{\"path\":\"../../public/fonts/rag/RagSans-1.4-ExtraLight.woff2\",\"weight\":\"200\"},{\"path\":\"../../public/fonts/rag/RagSans-1.4-ExtraLight.woff\",\"weight\":\"200\"},{\"path\":\"../../public/fonts/rag/RagSans-1.4-Light.woff2\",\"weight\":\"300\"},{\"path\":\"../../public/fonts/rag/RagSans-1.4-Light.woff\",\"weight\":\"300\"},{\"path\":\"../../public/fonts/rag/RagSans-1.4-Thin.woff2\",\"weight\":\"100\"},{\"path\":\"../../public/fonts/rag/RagSans-1.4-Thin.woff\",\"weight\":\"100\"}],\"display\":\"swap\",\"variable\":\"--rag\"}],\"variableName\":\"rag\"}");
import(/* webpackMode: "eager" */ "/app/packages/apollo/apollo-provider.js");
import(/* webpackMode: "eager" */ "/app/packages/ui/ActionState/ActionStateDesktop.tsx");
import(/* webpackMode: "eager" */ "/app/packages/ui/ActionState/ActionStateMobile.tsx");
import(/* webpackMode: "eager" */ "/app/packages/ui/ActiveArms.tsx");
import(/* webpackMode: "eager" */ "/app/packages/ui/Activities/Activities.tsx");
import(/* webpackMode: "eager" */ "/app/packages/ui/Brands.tsx");
import(/* webpackMode: "eager" */ "/app/packages/ui/BrandsValue.tsx");
import(/* webpackMode: "eager" */ "/app/packages/ui/ButtonWithArrow.tsx");
import(/* webpackMode: "eager" */ "/app/packages/ui/CenterImage.tsx");
import(/* webpackMode: "eager" */ "/app/packages/ui/CircleImage.tsx");
import(/* webpackMode: "eager" */ "/app/packages/ui/CircularGallery.tsx");
import(/* webpackMode: "eager" */ "/app/packages/ui/common/bit/MediaQueries.tsx");
import(/* webpackMode: "eager" */ "/app/packages/ui/Container.tsx");
import(/* webpackMode: "eager" */ "/app/packages/ui/ContentText/ContentText.tsx");
import(/* webpackMode: "eager" */ "/app/packages/ui/CustomAccordion.tsx");
import(/* webpackMode: "eager" */ "/app/packages/ui/CustomBreadcrumbs.tsx");
import(/* webpackMode: "eager" */ "/app/packages/ui/CustomerList.tsx");
import(/* webpackMode: "eager" */ "/app/packages/ui/DealsCarousel/DealsCarousel.tsx");
import(/* webpackMode: "eager" */ "/app/packages/ui/EnergyHeader.tsx");
import(/* webpackMode: "eager" */ "/app/packages/ui/Errors/GeneralErrorMsg.tsx");
import(/* webpackMode: "eager" */ "/app/packages/ui/FooterList.tsx");
import(/* webpackMode: "eager" */ "/app/packages/ui/GroupValues.tsx");
import(/* webpackMode: "eager" */ "/app/packages/ui/HeaderWithSearch.tsx");
import(/* webpackMode: "eager" */ "/app/packages/ui/Hero.tsx");
import(/* webpackMode: "eager" */ "/app/packages/ui/Hero/PositionedSearch.tsx");
import(/* webpackMode: "eager" */ "/app/packages/ui/Hero/PositionedStockStatus.tsx");
import(/* webpackMode: "eager" */ "/app/packages/ui/Hero/PositionedTextAndSearch.tsx");
import(/* webpackMode: "eager" */ "/app/packages/ui/History/History.tsx");
import(/* webpackMode: "eager" */ "/app/packages/ui/History/TimeLine.tsx");
import(/* webpackMode: "eager" */ "/app/packages/ui/icons/AccordionCloseIcon.tsx");
import(/* webpackMode: "eager" */ "/app/packages/ui/icons/AccordionExpandIcon.tsx");
import(/* webpackMode: "eager" */ "/app/packages/ui/icons/BannerBottomIcon.tsx");
import(/* webpackMode: "eager" */ "/app/packages/ui/icons/BannerTopIcon.tsx");
import(/* webpackMode: "eager" */ "/app/packages/ui/icons/CheckedCheckboxIcon.tsx");
import(/* webpackMode: "eager" */ "/app/packages/ui/icons/CheckedCircleIcon.tsx");
import(/* webpackMode: "eager" */ "/app/packages/ui/icons/CheckIcon.tsx");
import(/* webpackMode: "eager" */ "/app/packages/ui/icons/ContractIcon.tsx");
import(/* webpackMode: "eager" */ "/app/packages/ui/icons/FaqIcon.tsx");
import(/* webpackMode: "eager" */ "/app/packages/ui/icons/FormSubmitSuccessIcon.tsx");
import(/* webpackMode: "eager" */ "/app/packages/ui/icons/FormSuccessIcon.tsx");
import(/* webpackMode: "eager" */ "/app/packages/ui/icons/ReportsWrapper.tsx");
import(/* webpackMode: "eager" */ "/app/packages/ui/icons/ReportsWrapperSmall.tsx");
import(/* webpackMode: "eager" */ "/app/packages/ui/ImageContainer.tsx");
import(/* webpackMode: "eager" */ "/app/packages/ui/ImageTextBlock.tsx");
import(/* webpackMode: "eager" */ "/app/packages/ui/layout/Layout.tsx");
import(/* webpackMode: "eager" */ "/app/packages/ui/LinkComponent.tsx");
import(/* webpackMode: "eager" */ "/app/packages/ui/Lists/List.tsx");
import(/* webpackMode: "eager" */ "/app/packages/ui/MainContainer.tsx");
import(/* webpackMode: "eager" */ "/app/packages/ui/MainTitle.tsx");
import(/* webpackMode: "eager" */ "/app/packages/ui/MessagePage.tsx");
import(/* webpackMode: "eager" */ "/app/packages/ui/NavLink/NavLink.tsx");
import(/* webpackMode: "eager" */ "/app/packages/ui/NewActiveArms/NewActiveArms.tsx");
import(/* webpackMode: "eager" */ "/app/packages/ui/NewText/NewText.tsx");
import(/* webpackMode: "eager" */ "/app/packages/ui/NumbersComponent.tsx");
import(/* webpackMode: "eager" */ "/app/packages/ui/NumbersComponent/NumbersGrid.tsx");
import(/* webpackMode: "eager" */ "/app/packages/ui/Pazomat/PositionVector.tsx");
import(/* webpackMode: "eager" */ "/app/packages/ui/ProjectGallery/ProjectGallery.tsx");
import(/* webpackMode: "eager" */ "/app/packages/ui/ProjectGallerySwiper.tsx");
import(/* webpackMode: "eager" */ "/app/packages/ui/Quote.tsx");
import(/* webpackMode: "eager" */ "/app/packages/ui/SearchInput/SearchInput.tsx");
import(/* webpackMode: "eager" */ "/app/packages/ui/SectionTitle.tsx");
import(/* webpackMode: "eager" */ "/app/packages/ui/SideImageText.tsx");
import(/* webpackMode: "eager" */ "/app/packages/ui/StockDtoGraph.tsx");
import(/* webpackMode: "eager" */ "/app/packages/ui/SuccessFailJob.tsx");
import(/* webpackMode: "eager" */ "/app/packages/ui/SuccessFailState.tsx");
import(/* webpackMode: "eager" */ "/app/packages/ui/Table/CommonTable.tsx");
import(/* webpackMode: "eager" */ "/app/packages/ui/TableChart/TableChart.tsx");
import(/* webpackMode: "eager" */ "/app/packages/ui/TabPanel.tsx");
import(/* webpackMode: "eager" */ "/app/packages/ui/TabTitle.tsx");
import(/* webpackMode: "eager" */ "/app/packages/ui/Text/Text.tsx");
import(/* webpackMode: "eager" */ "/app/packages/ui/theme/theme.ts");
import(/* webpackMode: "eager" */ "/app/packages/ui/TitleTextLink/LinkButton.tsx");
import(/* webpackMode: "eager" */ "/app/packages/ui/TitleTextLink/TitleTextLink.tsx");
import(/* webpackMode: "eager" */ "/app/packages/ui/TitleWithLine/TitleWithLine.tsx");
import(/* webpackMode: "eager" */ "/app/packages/ui/TopBanner/TopBanner.tsx");
import(/* webpackMode: "eager" */ "/app/packages/ui/transitions/TransitionNumbersCounter.tsx");
import(/* webpackMode: "eager" */ "/app/packages/ui/transitions/TransitionUp.ts");
import(/* webpackMode: "eager" */ "/app/packages/ui/transitions/useTransitionUpStyles.ts");
import(/* webpackMode: "eager" */ "/app/packages/ui/TzabarLineChart.tsx");
import(/* webpackMode: "eager" */ "/app/packages/utils/gtm.ts");
import(/* webpackMode: "eager" */ "/app/packages/utils/ReactQueryContext.tsx");
import(/* webpackMode: "eager" */ "/app/packages/utils/searchResults.ts");
import(/* webpackMode: "eager" */ "/app/packages/utils/useApi.ts");
import(/* webpackMode: "eager" */ "/app/packages/utils/UseOutsideClick.tsx")